import React from 'react'
import styled from 'styled-components'
import { TransparentButton } from '@common/buttons'

import { FormWrapper } from './reportFormStyles'

const Report0620Form = () => {
  return (
    <FormWrapper>
      <form
        method='POST'
        action='https://onlinepunks.activehosted.com/proc.php'
        id='_form_43_'
        className='_form _form_43 _inline-form  _dark'
        noValidate={false}
      >
        <input type='hidden' name='u' value='43' />
        <input type='hidden' name='f' value='43' />
        <input type='hidden' name='s' />
        <input type='hidden' name='c' value='0' />
        <input type='hidden' name='m' value='0' />
        <input type='hidden' name='act' value='sub' />
        <input type='hidden' name='v' value='2' />
        <div className='_form-content'>
          <div className='_form_element _x53666083 _full_width _clear'>
            <div className='_form-title'>
              Der influData TikTok Statistik-Report - Juni 2020
            </div>
          </div>
          <div className='_form_element _x77596755 _full_width _clear'>
            <div className='_html-code'>
              <h3>
                Top-Listen deutschsprachiger und internationaler TikTok Creator.
              </h3>
            </div>
          </div>
          <div className='_form_element _x45994927 _full_width _clear'>
            <div className='_html-code'>
              <span>
                Die aktuellen Top-Listen der deutschsprachigen und
                internationalen TikTok-Creator und die Veränderung der letzten 4
                Wochen - nach Follower und Viewzahlen. Außerdem die
                meistverlinkten Creator und die meistbenutzten Hashtags und
                Challenges der deutschen Community.
              </span>
            </div>
          </div>
          <div className='_form_element _x72983913 _full_width '>
            <label className='_form-label'>Vorname & Nachname</label>
            <div className='_field-wrapper'>
              <input
                type='text'
                name='firstname'
                // placeholder='Vorname / Nachname'
                required
              />
            </div>
          </div>
          <div className='_form_element _x02541768 _full_width '>
            <label className='_form-label'>E-mail</label>
            <div className='_field-wrapper'>
              <input
                type='text'
                name='email'
                // placeholder='Deine Mail'
                required
              />
            </div>
          </div>
          <div className='_button-wrapper _full_width'>
            <TransparentButton id='_form_43_submit' type='submit'>
              Absenden
            </TransparentButton>
          </div>
          <br />
          <small>
            Mit Klicken des Buttons “Absenden” stimmen Sie zu, dass wir Ihre
            Daten gemäß unseren Datenschutzbestimmungen speichern und
            verarbeiten.
          </small>
          <div className='_clear-element'></div>
        </div>
        <div className='_form-thank-you' style={{ display: 'none' }}></div>
      </form>
    </FormWrapper>
  )
}

export default Report0620Form
