import React from 'react'
import PropTypes from 'prop-types'
import LandingLayout from '@layouts/LandingLayout'
import {
  ReportImgWrapper,
  ReportContainerWrapper,
} from '@common/styledWrappers'

const CommonReportContainer = ({ FormComponent, reportImageName }) => {
  return (
    <LandingLayout withReportGradient>
      <ReportContainerWrapper>
        <FormComponent />

        <ReportImgWrapper
          src={`../../images/${reportImageName}`}
          alt='report_logo'
        />
      </ReportContainerWrapper>
    </LandingLayout>
  )
}

CommonReportContainer.propTypes = {
  FormComponent: PropTypes.func.isRequired,
  reportImageName: PropTypes.string.isRequired,
}

export default CommonReportContainer
