import styled from 'styled-components'

export const Button = styled.button.attrs(props => ({
  type: props.type || 'button',
}))`
  outline: none;
  border: none;
  cursor: pointer;
  font-family: inherit;
  text-transform: uppercase;
  color: ${props => props.theme.color.white};
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
`

export const TransparentButton = styled(Button)`
  background: transparent;
  color: ${props =>
    props.isOnWhiteBg ? props.theme.color.primary : props.theme.color.white};
  font-weight: bold;
  letter-spacing: 0;
  font-size: 13px;
  border: 1px solid
    ${props =>
      props.isOnWhiteBg ? props.theme.color.primary : props.theme.color.white};
  border-radius: 14px;
  padding: 6px 9px;
  transition: all 0.5s ease;
  &:hover {
    color: ${props =>
      props.isOnWhiteBg ? props.theme.color.white : props.theme.color.primary};
    background: ${props =>
      props.isOnWhiteBg ? props.theme.color.primary : props.theme.color.white};
  }
  ${props =>
    props.isHovered &&
    `
    color: ${
      props.isOnWhiteBg ? props.theme.color.white : props.theme.color.primary
    };
    background: ${
      props.isOnWhiteBg ? props.theme.color.primary : props.theme.color.white
    };
  `}
`

export const WhiteButton = styled(Button)`
  background: ${props => props.theme.color.white};
  color: ${props => props.theme.color.primary};
  font-weight: bold;
  letter-spacing: 0.7px;
  font-size: 14px;
  box-shadow: 0px 0px 15px #00000029;
  border: 1px solid ${props => props.theme.color.primary};
  border-radius: 18px;
  padding: 7px 10px;
`
