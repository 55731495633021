import styled from 'styled-components'

export const FormWrapper = styled.div`
  width: fit-content;
  ${props => props.theme.media.lessThan('smallDesktop')`
    width: 45%; 
  `}
  ${props => props.theme.media.lessThan('900px')`
    width: 95%; 
  `}
  form {
    font-size: 14px;
    line-height: 1.6;
    // font-family: arial, helvetica, sans-serif;
    margin: 0;
  }
  form * {
    outline: 0;
  }
  ._form_hide {
    display: none;
    visibility: hidden;
  }
  ._form_show {
    display: block;
    visibility: visible;
  }
  form._form-top {
    top: 0;
  }
  form._form-bottom {
    bottom: 0;
  }
  form._form-left {
    left: 0;
  }
  form._form-right {
    right: 0;
  }
  form input[type='text'],
  form input[type='date'],
  form textarea {
    padding: 6px;
    height: auto;
    border: #979797 1px solid;
    border-radius: 4px;
    color: #000 !important;
    font-size: 14px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  form textarea {
    resize: none;
  }

  form ._button-wrapper {
    display: flex;
    justify-content: center;
  }

  form ._close-icon {
    cursor: pointer;
    background-image: url('https://d226aj4ao1t61q.cloudfront.net/esfkyjh1u_forms-close-dark.png');
    background-repeat: no-repeat;
    background-size: 14.2px 14.2px;
    position: absolute;
    display: block;
    top: 11px;
    right: 9px;
    overflow: hidden;
    width: 16.2px;
    height: 16.2px;
  }
  form ._close-icon:before {
    position: relative;
  }
  form ._form-body {
    margin-bottom: 30px;
  }
  form ._form-image-left {
    width: 150px;
    float: left;
  }
  form ._form-content-right {
    margin-left: 164px;
  }
  form ._form-branding {
    color: #fff;
    font-size: 10px;
    clear: both;
    text-align: left;
    margin-top: 30px;
    font-weight: 100;
  }
  form ._form-branding ._logo {
    display: block;
    width: 130px;
    height: 14px;
    margin-top: 6px;
    background-image: url('https://d226aj4ao1t61q.cloudfront.net/hh9ujqgv5_aclogo_li.png');
    background-size: 130px auto;
    background-repeat: no-repeat;
  }

  form ._form-label {
    padding-left: 10px;
    text-transform: uppercase;
  }

  form ._form-label,
  form ._form_element ._form-label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }
  form._dark ._form-branding {
    color: #333;
  }
  form._dark ._form-branding ._logo {
    background-image: url('https://d226aj4ao1t61q.cloudfront.net/jftq2c8s_aclogo_dk.png');
  }
  form ._form_element {
    position: relative;
    margin-bottom: 10px;
    font-size: 0;
    max-width: 100%;
  }
  form ._form_element * {
    font-size: 15px;
    color: #fff;
  }
  form ._form_element._clear {
    clear: both;
    width: 100%;
    float: none;
  }
  form ._form_element._clear:after {
    clear: left;
  }
  form ._form_element input[type='text'],
  form ._form_element input[type='date'],
  form ._form_element select,
  form ._form_element textarea:not(.g-recaptcha-response) {
    display: block;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  form ._field-wrapper {
    position: relative;
  }
  form ._inline-style {
    float: left;
  }
  form ._inline-style input[type='text'] {
    width: 150px;
  }
  form ._inline-style:not(._clear) + ._inline-style:not(._clear) {
    margin-left: 20px;
  }
  form ._form_element img._form-image {
    max-width: 100%;
  }
  form ._clear-element {
    clear: left;
  }
  form ._full_width {
    width: 100%;
  }
  form ._form_full_field {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
  form input[type='text']._has_error,
  form textarea._has_error {
    border: #f37c7b 1px solid;
  }
  form input[type='checkbox']._has_error {
    outline: #f37c7b 1px solid;
  }
  form ._error {
    display: block;
    position: absolute;
    font-size: 14px;
    z-index: 10000001;
  }
  form ._error._above {
    padding-bottom: 4px;
    bottom: 39px;
    right: 0;
  }
  form ._error._below {
    padding-top: 4px;
    top: 100%;
    right: 0;
  }
  form ._error._above ._error-arrow {
    bottom: 0;
    right: 15px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #f37c7b;
  }
  form ._error._below ._error-arrow {
    top: 0;
    right: 15px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #f37c7b;
  }
  form ._error-inner {
    padding: 8px 12px;
    background-color: #f37c7b;
    font-size: 14px;

    color: #fff;
    text-align: center;
    text-decoration: none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }
  form ._error-inner._form_error {
    margin-bottom: 5px;
    text-align: left;
  }
  form ._button-wrapper ._error-inner._form_error {
    position: static;
  }
  form ._error-inner._no_arrow {
    margin-bottom: 10px;
  }
  form ._error-arrow {
    position: absolute;
    width: 0;
    height: 0;
  }
  form ._error-html {
    margin-bottom: 10px;
  }
  .pika-single {
    z-index: 10000001 !important;
  }
  @media all and (min-width: 320px) and (max-width: 667px) {
    ::-webkit-scrollbar {
      display: none;
    }
    form {
      margin: 0;
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      box-sizing: border-box;
    }
    form * {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      font-size: 1em;
      color: #fff !important;
    }
    form ._form-content {
      margin: 0;
      width: 100%;
    }
    form ._form-inner {
      display: block;
      min-width: 100%;
    }
    form ._form-title,
    form ._inline-style {
      margin-top: 0;
      margin-right: 0;
      margin-left: 0;
    }
    form ._form-title {
      font-size: 1.2em;
    }
    form ._form_element {
      margin: 0 0 20px;
      padding: 0;
      width: 100%;
    }
    form ._form-element,
    form ._inline-style,
    form input[type='text'],
    form label,
    form p,
    form textarea:not(.g-recaptcha-response) {
      float: none;
      display: block;
      width: 100%;
    }
    form ._row._checkbox-radio label {
      display: inline;
    }
    form ._row,
    form p,
    form label {
      margin-bottom: 0.7em;
      width: 100%;
    }
    form ._row input[type='checkbox'],
    form ._row input[type='radio'] {
      margin: 0 !important;
      vertical-align: middle !important;
    }
    form ._row input[type='checkbox'] + span label {
      display: inline;
    }
    form ._row span label {
      margin: 0 !important;
      width: initial !important;
      vertical-align: middle !important;
    }
    form ._form-image {
      max-width: 100%;
      height: auto !important;
    }
    form input[type='text'] {
      padding-left: 10px;
      padding-right: 10px;
      font-size: 16px;
      line-height: 1.3em;
      -webkit-appearance: none;
    }
    form input[type='radio'],
    form input[type='checkbox'] {
      display: inline-block;
      width: 1.3em;
      height: 1.3em;
      font-size: 1em;
      margin: 0 0.3em 0 0;
      vertical-align: baseline;
    }

    form ._inline-style {
      margin: 20px 0 0 !important;
    }
  }
  form {
    position: relative;
    text-align: left;
    margin: 25px auto 0;
    padding: 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    *zoom: 1;
    background: transparent !important;
    border: 0px solid #b0b0b0 !important;
    width: 500px;
    -moz-border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    border-radius: 0px !important;
    // color: #030303 !important;
  }
  form ._form-title {
    font-size: 40px;
    line-height: 40px;
    font-weight: bold;
    margin-bottom: 50px;
  }
  form:before,
  form:after {
    content: ' ';
    display: table;
  }
  form:after {
    clear: both;
  }
  form._inline-style {
    width: auto;
    display: inline-block;
  }
  form._inline-style input[type='text'],
  form._inline-style input[type='date'] {
    padding: 10px 12px;
  }
  form._inline-style button._inline-style {
    position: relative;
    top: 27px;
  }
  form._inline-style p {
    margin: 0;
  }
  form._inline-style ._button-wrapper {
    position: relative;
    margin: 27px 12.5px 0 20px;
  }
  form ._form-thank-you {
    position: relative;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 18px;
  }
  @media all and (min-width: 320px) and (max-width: 667px) {
    form._inline-form._inline-style ._inline-style._button-wrapper {
      margin-top: 20px !important;
      margin-left: 0 !important;
    }
  }
  ._html-code {
    margin-bottom: 20px;
  }
  ._html-code h3 {
    font-weight: bold;
  }
  input {
    border-radius: 14px !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: none !important;

    width: 100%;
    height: 47px !important;
  }
`
