import React from 'react'
import availableLangs from '@localization/availableLangs'
import Report0620PageContainer from '@containers/ReportPagesContainers/Report0620PageContainer'
import SeoContainer from '@containers/SeoContainer'
import report0620FromScript from '../../externalScriptsCode/report0620FromScript'

const IndexPage = () => {
  return (
    <>
      <SeoContainer pageLang={availableLangs.de} pagePath='report0620'>
        <script async='true' dangerouslySetInnerHTML={{ __html: report0620FromScript }} />
      </SeoContainer>

      <Report0620PageContainer />
    </>
  )
}

export default IndexPage
