import React from 'react'
import Report0620Form from '@common/forms/Report0620Form'
import CommonReportContainer from './CommonReportContainer'

const Report0620PageContainer = () => {
  return (
    <CommonReportContainer
      FormComponent={Report0620Form}
      reportImageName='report_june.png'
    />
  )
}

export default Report0620PageContainer
