import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import localizedLabelStrings from '@localization/localizedLabelStrings'
import availableLangs from '@localization/availableLangs'

const SeoContainer = ({ pageLang, pagePath, title, description, keywords, children }) => {
  const defaultTitle = localizedLabelStrings[pageLang].pageTitle
  const defaultDescription = localizedLabelStrings[pageLang].pageDescription
  const defaultKeywords = localizedLabelStrings[pageLang].pageKeywords

  const urlPagePath = pagePath ? `${pagePath}/` : ''

  let canonicalHref
  let ogUrlContent
  let alternate

  if (pageLang === availableLangs.en) {
    const url = `${process.env.INFLUDATA_LANDING_URL}/${urlPagePath}`
    canonicalHref = url
    ogUrlContent = url
    alternate = {
      hreflang: availableLangs.de,
      href: `${process.env.INFLUDATA_LANDING_URL}/${availableLangs.de}/${urlPagePath}`,
    }
  } else {
    const url = `${process.env.INFLUDATA_LANDING_URL}/${pageLang}/${urlPagePath}`
    canonicalHref = url
    ogUrlContent = url
    alternate = {
      hreflang: availableLangs.en,
      href: `${process.env.INFLUDATA_LANDING_URL}/${urlPagePath}`,
    }
  }

  const isBrowser =
    typeof window !== 'undefined' && typeof window.document !== 'undefined'

  useEffect(() => {
    if (isBrowser) {
      const helmetTitle = document.getElementsByTagName('title')

      if (helmetTitle[1]?.outerHTML.includes('data-react-helmet')) {
        helmetTitle[1].remove()
      }
    }
  }, [isBrowser])

  return (
    <Helmet htmlAttributes={{ lang: pageLang }} key={pageLang}>
      <title>{title || defaultTitle}</title>

      <link rel='canonical' href={canonicalHref} />
      <link rel='alternate' hrefLang={alternate.hreflang} href={alternate.href} />

      <meta property='og:url' content={ogUrlContent} />
      <meta property='og:title' content={defaultTitle} />
      <meta property='og:description' content={description || defaultDescription} />
      <meta property='og:type' content='website' />
      <meta property='og:image' content='https://infludata.com/logo.png' />
      <meta property='og:site_name' content='Infludata' />

      <meta name='twitter:card' content='summary' />
      <meta name='twitter:site' content='@Infludata' />
      <meta name='twitter:title' content={defaultTitle} />
      <meta name='twitter:description' content={description || defaultDescription} />
      <meta name='twitter:creator' content='@Infludata' />
      <meta name='twitter:image:src' content='https://infludata.com/logo.png' />
      <meta name='twitter:domain' content='infludata.com' />
      <meta name='keywords' content={keywords || defaultKeywords} />
      {children}
    </Helmet>
  )
}

SeoContainer.propTypes = {
  pageLang: PropTypes.string,
  pagePath: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.instanceOf(null),
  ]),
}

SeoContainer.defaultProps = {
  pageLang: '',
  pagePath: '',
  title: '',
  description: '',
  keywords: '',
  children: null,
}

export default SeoContainer
